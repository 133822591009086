import propTypes from 'prop-types';
import '../../Blog.css';

/**
 * A function component that takes an array of image objects
 * and renders an <img> element for each image with the
 * image path, alt text, width, and height as its props.
 *
 * The component maps over the array of images and
 * returns an <img> element for each image. The key
 * prop for each <img> element is set to the image's
 * id, which is assumed to be a unique number or string.
 *
 * @param {{ body: { id: number, path: string, alt: string, width: string, height: string }[] }} props
 */
const PostInfoImage = ({ body }) => {
    return (
        body.map(({ id, path, alt, width, height }) => (
            <img key={id} src={`${window.location.origin}/${path}`} className='mt-2 mb-4' alt={alt} width={width} height={height} />
        ))
    );
};
export default PostInfoImage;

PostInfoImage.propTypes = {
    body: propTypes.arrayOf(
        propTypes.shape({
            id: propTypes.number.isRequired,
            path: propTypes.string.isRequired,
            alt: propTypes.string.isRequired,
            width: propTypes.string.isRequired,
            height: propTypes.string.isRequired
        }),
    ).isRequired
}