import React, { useEffect } from "react";
import { Button } from "react-bootstrap";

export default function GraciasPage() {
  useEffect(() => {
    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-877483091/jNBfCJTA-ZoZENOotaID",
      });
    }
  }, []);
  return (
    <section
      style={{
        backgroundImage: `url(/images/bghome.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingTop: "20rem",
        paddingBottom: "20rem",
        position: "relative", // Make the section a relative container
        overflow: "hidden", // Prevents any absolutely positioned child from overflowing
      }}
    >
      {/* Overlay */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark transparent overlay
          zIndex: 1, // Sits above the background image
        }}
      />

      <div className="container">
        <div
          className="row mb-5 text-white"
          style={{
            position: "relative", // This ensures content sits above the overlay
            zIndex: 2, // Higher zIndex than the overlay
            color: "white",
          }}
        >
          <div className="col-12 col-lg-7 mx-auto text-center text-white section-content">
            <h1 className="text-white">¡Gracias por contactarnos!</h1>
            <h1 className="my-5 fs-3 fw-bold text-white">SOLICITUD RECIBIDA</h1>
            <h4 className="fw-bold mb-0">
              Si deseas cotizar otro vehículo o saber mas de nosotros puedes
              volver a la pagina principal
            </h4>
            <Button
              variant="outline-light"
              className="nav-button mt-5"
              href="/"
            >
              Volver a cotizar
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
