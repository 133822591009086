import propTypes from 'prop-types';
import '../../Blog.css';

const PostInfoTitlePrimary = ( { title }) => {
    return (
        <h1 className="post-title-h1-primary">{ title }</h1>
    );
};
export default PostInfoTitlePrimary;

PostInfoTitlePrimary.propTypes = {
    title: propTypes.string.isRequired
}