import React from 'react';
import { Row, Col} from 'react-bootstrap';

const Ofrecemos = () => {
  // Asumiendo que tienes un array de imágenes con objetos que incluyen la ruta src
  const imagenes = [
    { src: '/images/ofrecemos1.png' },
    { src: '/images/ofrecemos2.png' },
    { src: '/images/ofrecemos3.png' },
    { src: '/images/ofrecemos4.png' },
    { src: '/images/ofrecemos5.png' },
    { src: '/images/ofrecemos6.png' },
  ];

  return (
    <div className="g-0" style={{ backgroundColor: '#F7FAEA', padding: '20px' }}>
      <h2 style={{ textAlign: 'center', margin: '20px 0' }}>Ofrecemos</h2>
      <Row className='g-0'>
        {imagenes.slice(0, 3).map((imagen, index) => (
          <Col key={index} xs={12} md={4} style={{display: 'flex', marginBottom: '15px', justifyContent: 'center'}}>
            <img src={imagen.src} alt={`Imagen ${index + 1}`} className="img-fluid" style={{ width: '75%'}} />
          </Col>
        ))}
      </Row>
      <Row className='g-0'>
        {imagenes.slice(3, 6).map((imagen, index) => (
          <Col key={index} xs={12} md={4} style={{display: 'flex', marginBottom: '15px', justifyContent: 'center'}}>
            <img src={imagen.src} alt={`Imagen ${index + 4}`} className="img-fluid" style={{ width: '75%' }}/>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Ofrecemos;
