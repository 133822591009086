import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FormQuote from './FormQuote'

const Home = () => {
    return ( 
    <>
        <div id='form' style={{
            backgroundImage: 'url("/images/bghome.png")', 
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            objectFit: 'fill',
            margin: 0,
            borderRadius: '16px',
            overflow: 'hidden'
          }}
          className='p-md-0 m-md-0 m-4 p-5 gy-md-0 gy-5 d-none d-md-block'>
        <Row className='g-0'>
        <Col md={6} xs={12} className="d-flex justify-content-start flex-column p-md-0 ">
            <h1 className='p-md-5 mt-md-5' style={{fontSize: '3rem' }}>Cotización inmediata y sin costo para trasladar tu vehículo</h1>
            <a href='tel:+1-870-455-6742' className='py-3 rounded-3 mx-5 fs-5 text-center text-decoration-none text-white w-75' style={{backgroundColor: '#F47553', borderColor: '#F47553'}}>Contactanos directamente: +1-870-455-6742</a>
        </Col>            
          <Col md={6} xs={12} className="p-5 d-none d-md-block mt-5" > {/* Remove horizontal padding on md screens */}
              <FormQuote />
          </Col>
        </Row>

        </div>
        <div id='form' style={{
            backgroundImage: 'url("/images/bghome.png")', 
            backgroundPosition: '10% 25%',
            backgroundSize: '700px 350px',
            backgroundRepeat: 'no-repeat',
            objectFit: 'fill',
            margin: 0,
            borderRadius: '16px',
            overflow: 'hidden',
            minHeight: '300px'
          }}
          className='p-md-0 m-md-0 m-4  gy-md-0 d-block d-md-none'>
        <Row className='g-0'>
        <Col md={6} xs={12} className="px-4 mb-5 pb-5 pt-2">
            <h1 className='p-0 ' style={{fontSize: '2rem', fontWeight: '600' }}>Cotización inmediata y sin costo para trasladar tu vehículo</h1>
        </Col>            
          <Col md={6} xs={12} className="p-5 d-none d-md-block mt-5" > {/* Remove horizontal padding on md screens */}
              <FormQuote />
          </Col>
        </Row>
        </div>
        <div className='d-flex justify-content-center d-md-none my-5 mx-auto '>
          <a href='tel:+1-870-455-6742' className='py-3 rounded-3 text-center text-decoration-none text-white w-75' style={{backgroundColor: '#F47553', borderColor: '#F47553'}}>LLamanos: +1-870-455-6742</a>
        </div>

    </>
  );
};

export default Home;

/* Frame 427318314 */
