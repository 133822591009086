import { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import BlogCarousel from '../components/blog/BlogCarousel';
import { useBlogContext, BlogProvider } from '../context/useBlogContext';
import '../components/blog/Blog.css';
import { Route, Routes } from 'react-router-dom';
import Post from '../components/blog/Post';

const Blog = () => {
    const { posts, loading, error } = useBlogContext();
    const [postsCards, setPostsCards] = useState([]);
    const [disabled, setDisabled] = useState(false);

    const postsMapAll = useMemo(() => posts.map((post) => ({
        id: post.id,
        title: post.title,
        resume: post.resume,
        cardImgPath: post.cardImgPath
    })), [posts]);

    useEffect(() => {
        const postsToCards = postsMapAll.slice(0, 3);
        setPostsCards(postsToCards);
        if (postsToCards.length < 3) {
            setDisabled(true);
        }
    }, [postsMapAll]);

    if (loading) {
        return <p>Cargando...</p>;
    }
    if (error) {
        return <p>{error}</p>;
    }

    const handleShowAll = () => {
        setPostsCards(postsMapAll);
        setDisabled(true);
    };

    return (

        <Fragment>
            <div className='w-100 px-4'>
                <Image src='/images/blog/blog_banner_1.png' className='w-100' />
            </div>
            <section className='d-flex align-items-center w-100 bg-blog p-lg-5 py-lg-5 py-5 flex-column gap-5' id="blog">
                <BlogCarousel posts={postsCards} justify="start" />
                <div className='d-flex justify-content-center'>
                    <Button className={` px-5 py-2 rounded-1 carousel-card-button ` + (disabled ? 'd-none' : '')} onClick={handleShowAll} >Ver Todos</Button>
                </div>
            </section>
        </Fragment>

    );
};

export default function BlogPage() {
    return (
        <BlogProvider>
            <Routes>
                <Route path="" element={<Blog />} />
                <Route path="post/:id" element={<Post />} />
            </Routes>
        </BlogProvider>
    )
}