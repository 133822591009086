import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Info = () => {
  return (
      <Row className='g-0' id='about-us'>
        <Col md={6} xs={12} className='mt-5 px-4'>
          <h1 className='mt-md-5'>¿Por qué somos tu mejor opción?</h1>
          <p>NoManejes.com es una empresa del grupo Garlas LLC  y es la compañía de transporte de autos número 1 de los hispanos en USA, con más de 6 años de experiencia y una cabeza hispana a cargo que entiende tus necesidades.</p>
        </Col>
        <Col md={6} xs={12} className="d-flex flex-column align-items-center">
          <img src="images/info1.png" alt="Descripción imagen 1" className="img-fluid mb-3 mt-5 w-75 h-75" />
          <img src="images/info2.png" alt="Descripción imagen 2" className="img-fluid mb-3 w-75 h-75"/>
          <img src="images/info3.png" alt="Descripción imagen 3" className="img-fluid mb-3 w-75 h-75"/>
        </Col>
      </Row>
  );
};

export default Info;
