import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { useCustomNavigate } from '../../utils/customNavigation';

function Navi() {
  const customNavigate = useCustomNavigate();
  return (

    <Navbar bg="light" expand="md" sticky="top" className='py-3'>
      <Container className="navbar-container ">
        <Navbar.Brand href="#home">
          <img
            src="/images/nomanejes.png"
            width={100}
            className="d-inline-block align-top"
            alt="Logo TCP"
            role="button"
            onClick={() => customNavigate('#form')}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav-links ms-auto">
            <Nav.Link href="#servicios" className='nav-link-normal mx-2' style={{ color: '#F15025', }} onClick={() => customNavigate('#form')}>Inicio</Nav.Link>
            <Nav.Link href="#terminos" className='nav-link-normal mx-2' style={{ color: '#F15025', }} onClick={() => customNavigate('#about-us')}>Sobre nosotros</Nav.Link>
            <Nav.Link href="/terms" className='nav-link-normal mx-2 ' style={{ color: '#F15025', }}>Terminos y condiciones</Nav.Link>
            <Nav.Link href="/blog" className='nav-link-normal mx-2 ' style={{ color: '#F15025', }}>Blog</Nav.Link>
            <Button variant="outline-light" className='nav-button ms-2' onClick={() => customNavigate('#form')}>
              Cotizar
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navi;

