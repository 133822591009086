import React, { useState } from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import "../../step.css";

const Faqs = () => {
  const accordionData = [
    {
      title: "¿Cuánto tiempo tarda la entrega de un vehículo?",
      content:
        "El tiempo de entrega dependerá de la cantidad de millas recorridas y de algunas condiciones como el clima y el tráfico. Rutas entre 500 y 1,000 millas pueden tardar de 2 a 3 días. Rutas entre 1,000 y 2,000 millas pueden tardar de 4 a 7 días. Rutas de más de 2,000 millas pueden tardar entre 7 y 12 días.",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
    {
      title: "¿Qué está cubierto por el seguro?",
      content:
        "SOLO cubrimos el seguro del vehículo en nuestra cotización. Sus pertenencias en el auto no forman parte de la cotización del seguro. Tenga en cuenta que el seguro de estas pertenencias en el vehículo no está en su contrato vinculante. Cualquier daño a las pertenencias dentro del automóvil no será responsabilidad de NoManejes.",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
    {
      title: "¿Debo estar presente todo el tiempo?",
      content:
        "No, no tienes que estar presente durante toda la duración del proceso de recogida o entrega del envío. Sin embargo, recomendamos encarecidamente tener a alguien que sea mayor de edad disponible durante la carga o descarga del vehículo.",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
    {
      title: "¿Puede enviar su coche con cosas dentro?",
      content:
        "¡Sí! Los clientes no siempre tienen tiempo para sacar sus pertenencias de su vehículo antes del inicio del servicio, por lo que a menudo transportamos coches con pequeñas pertenencias dentro. Entendemos y queremos hacer nuestra parte para que nuestros clientes se sientan cómodos. Nuestros transportistas de confianza y profesionales transportarán su vehículo sin ninguna pérdida. Tenga en cuenta que el seguro de estas posesiones en el vehículo no está en su contrato vinculante. Cualquier daño a las pertenencias dentro del coche no será responsabilidad de NoManejes. Por favor, retire cualquier pertenencia colocada en el asiento del conductor.",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
    {
      title: "¿Cómo se calcula la tarifa de transporte de su vehículo?",
      content:
        "Nos gusta mantener las cosas eficientes y directas. Pedimos a nuestros clientes que proporcionen detalles básicos sobre sus vehículos, como el tamaño, el peso, la distancia entre la recogida y la entrega, y la elección entre el transporte de vehículos abierto o cerrado. Al hacerlo, podemos evaluar con precisión las tarifas de transporte del vehículo en consecuencia. A diferencia de otras compañías de transporte, no solicitamos tarifas adicionales.",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
    {
      title: "¿Qué pasa si quiero pagar con tarjeta de crédito?",
      content:
        "Aceptamos diversas formas de pago que van desde tarjetas de crédito hasta PayPal. Los clientes pueden pagar en efectivo contra entrega (COD), pero se requiere un pago de depósito para asegurar su servicio de transporte.",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
    {
      title:
        "¿Qué pasa si mi coche es pesado? ¿Eso resulta en un presupuesto de entrega más alto?",
      content:
        "Calculamos nuestras tarifas de acuerdo con el tamaño y el peso del vehículo sin cargos adicionales al cliente.",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
    {
      title: "¿Hay un pago inicial?",
      content:
        "No, no hay tarifas ocultas ni cargos por consultas. Sin embargo, si opta por pagar en efectivo contra entrega (COD), se le requerirá hacer un depósito hacia el servicio de transporte seleccionado para asegurar su reserva.",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
    {
      title: "¿Qué pasa si quiero una entrega inmediata?",
      content:
        "NoManejes está disponible las 24 horas del día, los 7 días de la semana, lo que nos permite apoyar a nuestros clientes durante todo el año cuando nos necesitan. Los clientes pueden llamarnos en cualquier momento y reservar un servicio de transporte de vehículos. Le aseguramos que trabajaremos para entregar su vehículo de la manera más segura y rápida posible a la ubicación que desee.",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
    {
      title: "¿Pueden transportar para ferias de autos?",
      content:
        "Sí, ¡estamos disponibles para servicios de transportistas de ferias de autos! Nos enorgullece participar en ayudar a su feria de autos como su transportista elegido. ¡Le deseamos mucha suerte!",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
    {
      title: "¿Cómo elijo una empresa de transporte de automóviles?",
      content:
        "Decidir qué empresa se adapta mejor a sus necesidades de transporte de automóviles puede ser estresante y agotador. Recomendamos investigar cualquier empresa antes de entregarles su vehículo. Los indicadores a tener en cuenta son el nivel de experiencia del transportista, la reputación de la empresa en el mercado y la calidad de los servicios de consulta que ofrecen las empresas.\n\nTambién puede leer y explorar opiniones de la empresa. Las reseñas pueden revelar mucho sobre la experiencia del cliente de una empresa y ayudarlo a tomar la decisión correcta.\n\n¡Le animamos a leer nuestras reseñas y comunicarse si tiene alguna pregunta! Nuestras opiniones de los clientes reflejan nuestros años de servicio y dedicación a nuestros clientes. En NoManejes somos la empresa de transporte más experimentada y de mejor reputación en los Estados Unidos. ¡Tenemos clientes de todos los estados! ¡Puede confiar en nosotros para hacer el trabajo bien hecho!",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
    {
      title: "¿Qué hago si mi coche se daña durante el transporte?",
      content:
        "Lo primero es lo primero, NO RECIBA el conocimiento de embarque cuando le entreguen su vehículo. Si su coche no está en las mismas condiciones exactas en las que lo envió, llame inmediatamente al servicio de atención al cliente de NoManejes. Su automóvil puede llegar un poco sucio por fuera.",
      imgOpen: "/images/stepbuttom2.png",
      imgClose: "/images/stepbuttom.png",
    },
  ];

  // Divide los datos en dos mitades
  const firstHalfData = accordionData.slice(0, accordionData.length / 2);
  const secondHalfData = accordionData.slice(accordionData.length / 2);

  // Estados para controlar los ítems abiertos
  const [openIndexFirstHalf, setOpenIndexFirstHalf] = useState(null);
  const [openIndexSecondHalf, setOpenIndexSecondHalf] = useState(null);

  const toggleAccordionFirstHalf = (index) => {
    if (openIndexFirstHalf === index) {
      setOpenIndexFirstHalf(null);
    } else {
      setOpenIndexFirstHalf(index);
      setOpenIndexSecondHalf(null); // Cierra los ítems de la segunda mitad cuando se abre uno de la primera
    }
  };

  const toggleAccordionSecondHalf = (index) => {
    if (openIndexSecondHalf === index) {
      setOpenIndexSecondHalf(null);
    } else {
      setOpenIndexSecondHalf(index);
      setOpenIndexFirstHalf(null); // Cierra los ítems de la primera mitad cuando se abre uno de la segunda
    }
  };

  return (
    <div className="mt-3 g-0" id="faqs">
      <Row className="g-0">
        <h1 className="mt-5 text-center mb-5 color-review">
          Preguntas frecuentes
        </h1>

        <Col md={6} xs={12} className="aling-justify-end">
          {firstHalfData.map((step, index) => (
            <Accordion
              defaultActiveKey="99"
              className=" custom-accordion mt-3 px-5 w-90"
              style={{ marginLeft: "0px" }}
              key={index}
            >
              <Accordion.Item eventKey={String(index)}>
                <Accordion.Header
                  onClick={() => toggleAccordionFirstHalf(index)}
                >
                  <div style={{ flex: 1 }} className="fw-bold">
                    {step.title}
                  </div>
                  <img
                    src={
                      openIndexFirstHalf === index
                        ? step.imgOpen
                        : step.imgClose
                    }
                    alt="Toggle"
                    className="accordion-toggle-icon"
                  />
                </Accordion.Header>
                <Accordion.Body style={{ background: "#F7FAEA" }}>
                  {step.content}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </Col>
        <Col md={6} xs={12} className="aling-justify-start g-0">
          {secondHalfData.map((step, index) => (
            <Accordion
              defaultActiveKey="99"
              className="custom-accordion mt-3 px-5 w-90"
              style={{ marginRight: "0px" }}
              key={index + firstHalfData.length}
            >
              <Accordion.Item eventKey={String(index + firstHalfData.length)}>
                <Accordion.Header
                  onClick={() => toggleAccordionSecondHalf(index)}
                >
                  <div style={{ flex: 1 }} className="fw-bold">
                    {step.title}
                  </div>
                  <img
                    src={
                      openIndexSecondHalf === index
                        ? step.imgOpen
                        : step.imgClose
                    }
                    alt="Toggle"
                    className="accordion-toggle-icon"
                  />
                </Accordion.Header>
                <Accordion.Body style={{ background: "#F7FAEA" }}>
                  {step.content}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default Faqs;
